.spinner-container {
  display: inline-block;
  width: 128px;
  height: 128px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
