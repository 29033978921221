body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: silver;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: silver;
}
